.background__division--wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.background__division {
  display: flex;
  flex-flow: row-wrap;
  align-items: stretch;
  flex-grow: 1;
  transition: flex-grow ease-in-out .5s;
  cursor: pointer;
}

.background__division:hover {
  flex-grow: 1.5;
}

.background__division--active, .background__division--active.background__division:hover {
  flex-grow: 5;
  cursor: default;
}

.background__division--hide {
  flex-grow: 0;
}


.background__division--dev {
  background-image: url('./shared/img/code.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background__division--photos {
  background-image: url('./shared/img/sneeuwuil.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.background__division--about {
  background-image: url('./shared/img/me.webp');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 768px) {
  .background__division--wrapper {
    height: 100%;
    width: 100%;
    flex-direction: column;
  }
}