.content {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  top: calc(100% / 2.5);
}

.content > p {
  display: flex;
  max-width: 60%;
  height: fit-content;
  max-height: calc(100% * (3/4));
  position: relative;
  overflow-wrap: anywhere;
}

@media only screen and (min-width: 768px) {
  .content > p {
    max-width: calc(100% / 3);
  }
}

.content--fade-in {
  animation: fade-in .5s;
}

.content--fade-out {
  animation: fade-out .5s;
}

@keyframes fade-in {
  0%, 25% { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes fade-out {
  0%, 75% { opacity: 1; }
  100% { opacity: 0; }
}