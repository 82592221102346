.footer__wrapper {
  width: 100%;
  height: 4rem;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.footer__social {
  position: relative;
  margin: 0 0.5rem;
  width: 40px;
  height: 32px;
  pointer-events: auto;
}

.footer__social:hover {
  top: -1px;
}

.footer__social--linkedin > a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../shared/img/linkedin.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer__social--github > a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("../../shared/img/github.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.footer__social--mail > a {
  display: block;
  position: relative;
  width: 100%;
  top: -4px;
  height: 40px;
  background-image: url("../../shared/img/mail.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}