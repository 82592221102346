.header__wrapper {
  width: 100%;
  display: flex;
  position: absolute;
  justify-content: center;
  pointer-events: none;
  top: calc((100% / 2) - 1rem);
  transition: top ease-in-out 0.5s;
}

.header {
  position: absolute;
  color: #FFF;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: min-content;
  background: #0000006e;
}

.header__wrapper--top {
  top: 5rem;
}

.header__wrapper--blink {
  animation: blink 0.5s;
}

.header__cta {
  position: relative;
  top: 2.5rem;
  color: #FFF;
  pointer-events: auto;
  cursor: pointer;
}

.header__back {
  position: relative;
  top: 2.5rem;
  color: #FFF;
  pointer-events: auto;
  cursor: pointer;
}

.italic {
  font-style: italic;
}

.lightest {
  font-weight: 100;
}

@keyframes blink {
  0%, 100% { opacity: 1; }
  25%, 75% { opacity: 0; }
}

@keyframes fade {
  0%, 100% { opacity: 1; }
  10%, 90% { opacity: 0; }
}